import * as React from 'react';
import * as signalR from "@microsoft/signalr";
import { BrowserRouter as Router, Redirect } from 'react-router-dom';

import style from '../../css/index.module.css';

let login: HTMLInputElement;
let password: HTMLInputElement;

const succesfullAuthorization: Event = new Event('succesfullAuthorization');
const connection = new signalR.HubConnectionBuilder().withUrl('/authorizationHub').build();

connection.start();

function checkAuthorize() {
    if (login != null && password != null) {
        connection.invoke<boolean>('CheckLoginAndPassword', login.value, password.value).then((result: boolean) => {
            if (result) {
                document.dispatchEvent(succesfullAuthorization);
            } else {
                alert('Проверьте правильность ввода логина и пароля.');
            }
        });
    }
}

const keyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code == 'Enter') {
        checkAuthorize();
    }
}

export function Index() {
    return (
        <div className={style.root}>
            <div>
                <Router>
                    <Redirect from='' to='/login' />
                </Router>
                <header >
                    <h1>SLP System.</h1>
                </header>
                <main>
                    <div>
                        <fieldset>
                            <legend>Вход</legend>
                            <input onKeyUp={keyUp} name="userNickname" id="userNickname" placeholder="Имя учётной записи" ref={ref => login = ref as HTMLInputElement} />
                            <input onKeyUp={keyUp} type="password" name="userPassword" id="userPassword" placeholder="Пароль" ref={ref => password = ref as HTMLInputElement} />
                            <button onClick={checkAuthorize}>Войти</button>
                        </fieldset>
                    </div>
                </main>
                <footer>
                    <hr />
                    <address>
                        <a href="https://vk.com/sovetckiysouz" target="_blank"> Andrew (<em>andrewmat2000</em>) Matveev </a><br />
                        <a href="https://vk.com/id12488633" target="_blank"> Andrew (<em>Rivand</em>) Kuznetsov </a><br />
                        <a href="mailto:fieldywork@yandex.ru"> Email to us. </a><br />
                    </address>
                </footer>
            </div>
        </div>);
}