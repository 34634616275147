import * as React from 'react';
import 'leaflet/dist/leaflet.css';
import map_manager from '../MainPanelScripts/Map/map_manager';
import style from '../../css/map.module.css';

export class Map extends React.Component {
  mapPanel: HTMLDivElement | null;
  constructor(props: any) {
    super(props);
    this.mapPanel = null;
  }
  componentDidMount() {
    map_manager.Start();
  }
  render() {
    return (
      <div className={style.root}>

        <div id='mapid'></div>
        <button id="show_map_panel_button">≡</button>
        <div id="map_panel" className="map-panel" ref={ref => this.mapPanel = ref as HTMLDivElement}>
          <h3 id="realTimeHeader">✓ Реальное время</h3>
          <div>
            <input type="checkbox" id="show_tracks_checkbox" name="show_tracks" defaultChecked={map_manager.Tracker.turnOn}/> отображать треки<br />
          </div>
          <p>Точек в треке</p>
          <input type="text" id="amount_of_track_point_textinput" name="amount_of_track_point" defaultValue={map_manager.Tracker.amountOfPoints} />
          <br /> <br />  <br />

          <div>
            <input type="checkbox" id="realtime_filter_checkbox" name="show_tracks" defaultChecked={map_manager.Filter.turnOn}/> включить фильтр<br />
          </div>
          <p>Номера устройств</p>
          <input type="text" id="realtime_filtered_devices_textinput" name="amount_of_track_point" defaultValue={map_manager.Filter.filterLabel} />
          <br /> <br />
          <div>
            <input type="checkbox" id="show_tracks_checkbox" name="show_tracks" defaultChecked={map_manager.circleEnabled} onChange={
              function (event: React.ChangeEvent) {
                let input = event.target as HTMLInputElement;
                map_manager.ChangeCircleEnable(input.checked);
              }
            } /> отображать радиус бс<br />
          </div>
          <br /> <br />
          <hr />
          <h3 id="historyHeader">История</h3>
          <p>Начало измерений</p>
          <div>
            <input id="history_start_date" type="date" defaultValue={map_manager.EpdsHistory.startDateLabel} />
            <input id="history_start_time" type="time" defaultValue={map_manager.EpdsHistory.startTimeLabel} />
          </div>
          <p>Конец измерений</p>
          <div>
            <input id="history_end_date" type="date" defaultValue={map_manager.EpdsHistory.endDateLabel}/>
            <input id="history_end_time" type="time" defaultValue={map_manager.EpdsHistory.endTimeLabel}/>
          </div>
          <p>Номера устройств</p>
          <input type="text" id="history_filtered_devices_textinput" defaultValue={map_manager.EpdsHistory.filterLabel} /><br />
          <br />
          <button id="show_history_button">Показать историю</button>
        </div>
      </div>
    );
  }
}
