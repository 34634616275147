import * as signalR from '@microsoft/signalr';
import Record from './record';
import MessageManager from './message_manager';
import { isRealTime } from '../../Views/slp_data';
import record_types from '../SlpData/record_types'

let lastStart = new Date();
let lastEnd = new Date();
let signalRConnection: signalR.HubConnection;

export async function OnDateChange() {
  if (lastStart != MessageManager.startDate || lastEnd != MessageManager.endDate) {
    MessageManager.lastMessageId = 0;
    lastStart = new Date(Date.UTC(MessageManager.startDate.getFullYear(),
      MessageManager.startDate.getMonth(),
      MessageManager.startDate.getDate(),
      MessageManager.startDate.getHours(),
      MessageManager.startDate.getMinutes()));

    lastEnd = new Date(Date.UTC(MessageManager.endDate.getFullYear(),
      MessageManager.endDate.getMonth(),
      MessageManager.endDate.getDate(),
      MessageManager.endDate.getHours(),
      MessageManager.endDate.getMinutes()));

    let records = await signalRConnection.invoke('GetRecordsByData', lastStart, lastEnd, Array.from(MessageManager.filteredRecType));

    const step = 5000;

    let i = 0;

    for (let rec of MessageManager.allRecords) {
      rec.RemoveFromTextArea();
    }

    MessageManager.allRecords = [];

    while (i < records.length) {
      if (i + 1 < records.length - step) {
        let newRecord = new Record(records[i]);
        MessageManager.AddRecord(newRecord);
        i++;
        while (i % step != 0) {
          let newRecord = new Record(records[i]);
          MessageManager.AddRecord(newRecord);
          i++;
        }
        await new Promise(resolve => setTimeout(resolve, 250));
      }
      else {
        let newRecord = new Record(records[i]);
        MessageManager.AddRecord(newRecord);
        i++;
      }
    }
    MessageManager.UpdatingDisplayOfRecords();
  }
};


export default function () {
  let textArea = document.getElementById('slp_data_area_fix') as HTMLDivElement;
  Record.textArea = textArea;
  MessageManager.textArea = textArea;

  // --------- Логика slp_data_panel ---------
  // Фильтр по номеру БС
  let filterBsNumberInputChanged = (value: string) => {
    MessageManager.SetNewBsNumsForFiltering(value);
    MessageManager.UpdatingDisplayOfRecords();
  }
  let filterBsNumsTextinput = document.getElementById('sdp_filter_bs_nums_textinput') as HTMLInputElement;
  filterBsNumsTextinput.addEventListener('change', (event) => {
    filterBsNumberInputChanged(filterBsNumsTextinput.value);
  });

  // Фильтр по номеру EPD
  let filterEpdNumsTextinputChanged = (value: string) => {
    MessageManager.SetNewEpdIdForFiltering(value);
    MessageManager.UpdatingDisplayOfRecords();
  }
  let filterEpdNumsTextinput = document.getElementById('sdp_filter_epd_nums_textinput') as HTMLInputElement;
  filterEpdNumsTextinput.addEventListener('change', (event) => {
    filterEpdNumsTextinputChanged(filterEpdNumsTextinput.value);
  });

  // Чекбокс фильтра по типу SU.
  let showSuRecordsCheckbox = document.getElementById('show_su_records_checkbox') as HTMLInputElement;
  showSuRecordsCheckbox.checked = MessageManager.filteredRecType.size == 0 ? true : MessageManager.filteredRecType.has(record_types.Su);
  showSuRecordsCheckbox.addEventListener('click', (event) => {
    if (showSuRecordsCheckbox.checked) {
      MessageManager.AddRecordTypeIntoFilter(record_types.Su);
      MessageManager.AddRecordTypeIntoFilter(record_types.DeviceRegistrationRequest);
      MessageManager.AddRecordTypeIntoFilter(record_types.DeviceFirmwareVersion);
      MessageManager.AddRecordTypeIntoFilter(record_types.ConfirmDeviceRegistration);
      MessageManager.UpdatingDisplayOfRecords();
    } else {
      MessageManager.RemoveRecordTypeFromFilter(record_types.Su);
      MessageManager.RemoveRecordTypeFromFilter(record_types.DeviceRegistrationRequest);
      MessageManager.RemoveRecordTypeFromFilter(record_types.DeviceFirmwareVersion);
      MessageManager.RemoveRecordTypeFromFilter(record_types.ConfirmDeviceRegistration);
      MessageManager.UpdatingDisplayOfRecords();
    };
  });


  // Чекбокс фильтра по типу TelemetryData.
  let showTelemetryRecordsCheckbox = document.getElementById('show_telemetry_records_checkbox') as HTMLInputElement;
  showTelemetryRecordsCheckbox.checked = MessageManager.filteredRecType.size == 0 ? true : MessageManager.filteredRecType.has(record_types.TelemetryData);
  showTelemetryRecordsCheckbox.addEventListener('click', (event) => {
    if (showTelemetryRecordsCheckbox.checked) {
      MessageManager.AddRecordTypeIntoFilter(record_types.TelemetryData);
      MessageManager.UpdatingDisplayOfRecords();
    } else {
      MessageManager.RemoveRecordTypeFromFilter(record_types.TelemetryData);
      MessageManager.UpdatingDisplayOfRecords();
    };
  });


  // Чекбокс фильтра по типу Lbs.
  let showLbsRecordsCheckbox = document.getElementById('show_lbs_records_checkbox') as HTMLInputElement;
  showLbsRecordsCheckbox.checked = MessageManager.filteredRecType.size == 0 ? true : MessageManager.filteredRecType.has(record_types.Lbs);
  showLbsRecordsCheckbox.addEventListener('click', (event) => {
    if (showLbsRecordsCheckbox.checked) {
      MessageManager.AddRecordTypeIntoFilter(record_types.Lbs);
      MessageManager.UpdatingDisplayOfRecords();
    } else {
      MessageManager.RemoveRecordTypeFromFilter(record_types.Lbs);
      MessageManager.UpdatingDisplayOfRecords();
    };
  });


  // Чекбокс фильтра по типу Lbsm.
  let showLbsmRecordsCheckbox = document.getElementById('show_lbsm_records_checkbox') as HTMLInputElement;
  showLbsmRecordsCheckbox.checked = MessageManager.filteredRecType.size == 0 ? true : MessageManager.filteredRecType.has(record_types.Lbsm);
  showLbsmRecordsCheckbox.addEventListener('click', (event) => {
    if (showLbsmRecordsCheckbox.checked) {
      MessageManager.AddRecordTypeIntoFilter(record_types.Lbsm);
      MessageManager.UpdatingDisplayOfRecords();
    } else {
      MessageManager.RemoveRecordTypeFromFilter(record_types.Lbsm);
      MessageManager.UpdatingDisplayOfRecords();
    };
  });


  // Чекбокс фильтра по типу Modem.
  let showModemRecordsCheckbox = document.getElementById('show_modem_records_checkbox') as HTMLInputElement;
  showModemRecordsCheckbox.checked = MessageManager.filteredRecType.size == 0 ? true : MessageManager.filteredRecType.has(record_types.Modem);
  showModemRecordsCheckbox.addEventListener('click', (event) => {
    if (showModemRecordsCheckbox.checked) {
      MessageManager.AddRecordTypeIntoFilter(record_types.Modem);
      MessageManager.UpdatingDisplayOfRecords();
    } else {
      MessageManager.RemoveRecordTypeFromFilter(record_types.Modem);
      MessageManager.UpdatingDisplayOfRecords();
    };
  });


  // --------- SignalR  ---------
  signalRConnection = new signalR.HubConnectionBuilder().withUrl('/slpDataHub').build();

  signalRConnection.on('NewBsRecords', function (bsRecords, newLastMessageId) {
    MessageManager.lastMessageId = newLastMessageId;
    for (let recordModel of bsRecords) {
      let newRecord = new Record(recordModel);
      MessageManager.AddRecord(newRecord);

      if (MessageManager.filteredBs.size != 0 && !MessageManager.filteredBs.has(newRecord.bsId)) continue; // Фильтр по номеру БС.
      if (MessageManager.recordTypesVals.includes(newRecord.type) &&
        MessageManager.filteredEpdId.size != 0 && !MessageManager.filteredEpdId.has(newRecord.epdId)) continue; // Фильтр по номеру устройства.
      if (!MessageManager.filteredRecType.has(newRecord.type)) continue; // Фильтр по типу устройства.
      if (MessageManager.startDate >= newRecord.time || MessageManager.endDate <= newRecord.time) continue;
      // Если мы прошли все фильтры то добавляем запись на экран.
      MessageManager.AppendRecordToTextArea(newRecord);
      MessageManager.amountRecordsOnScreen++;
      if (MessageManager.amountRecordsOnScreen > MessageManager.maxRecordsOnScreen) {
        MessageManager.visibleRecords[MessageManager.visibleRecords.length - MessageManager.amountRecordsOnScreen].RemoveFromTextArea();
        MessageManager.amountRecordsOnScreen--;
      }
    }
    if (MessageManager.autoscrollingIsEnabled) textArea.scrollTop = textArea.scrollHeight;
  });

  signalRConnection.start().then(function () {
  }).catch(function (err) {
    return console.error(err.toString());
  });

  setInterval(() => {
    if (isRealTime) {
      signalRConnection.invoke('GetNewBsRecords', MessageManager.lastMessageId).catch(function (err) {
        return console.error(err.toString());
      });
    } else { }
  }, 1000);
}