export default
  {
    Su: 1,
    TelemetryData: 2,
    Lbs: 3,
    DeviceRegistrationRequest: 4,
    DeviceFirmwareVersion: 5,
    ConfirmDeviceRegistration: 6,
    Modem: 7,
    Lbsm: 8,
  };