import * as React from 'react';
import launch, { OnDateChange } from '../MainPanelScripts/SlpData/slp_data_manager';
import MessageManager from '../MainPanelScripts/SlpData/message_manager';
import style from '../../css/slp_data.module.css';
import gif from '../../img/css-loader.gif';


let isRealTime = true;
let realTimeLabel: HTMLHeadingElement;
let historyLabel: HTMLHeadingElement;
let textArea: HTMLDivElement;
let modemCheckBox: HTMLInputElement;
let lbsmCheckBox: HTMLInputElement;
const defaultStartDate: Date = new Date(0);
const defaultEndDate: Date = new Date(3000, 1, 1, 1, 1, 1, 1);

let slpDataPanel: HTMLDivElement;

class dates {
  static startDate: HTMLInputElement;
  static startTime: HTMLInputElement;
  static endDate: HTMLInputElement;
  static endTime: HTMLInputElement;
  static setToDefault() {
    if (dates != null) {
      if (dates.startDate != null)
        dates.startDate.value = '';
      if (dates.endDate != null)
        dates.endDate.value = '';
      if (dates.startTime != null)
        dates.startTime.value = '';
      if (dates.endTime != null)
        dates.endTime.value = '';
    }
  }
}

function makeWithChilden(parent: HTMLDivElement, func: Function) {
  for (let i = 0; i < parent.childNodes.length; i++) {
    let node = parent.childNodes[i] as HTMLDivElement;
    if (node != null) {
      func(node);
    }
  }
}

function setLineBreak(isInNeed: boolean) {
  if (isInNeed) {
    textArea.style.overflowX = 'hidden';
    makeWithChilden(textArea, function (node: HTMLDivElement) {
      node.style.width = 'auto';
    });
  } else {
    textArea.style.overflowX = 'auto';
    makeWithChilden(textArea, function (node: HTMLDivElement) {
      node.style.width = 'max-content';
    });
  }
  MessageManager.lineBreak = isInNeed;
}

function setHistory(isHistory: boolean) {
  isRealTime = !isHistory;
  modemCheckBox.disabled = isHistory;
  if (isHistory) {
    realTimeLabel.innerHTML = 'Реальное время';
    historyLabel.innerHTML = '✓ История';
  }
  else {
    realTimeLabel.innerHTML = '✓ Реальное время';
    historyLabel.innerHTML = 'История';
  }
}

const getDateTimeByString = (date: any, time: any, isStarter: any) => {
  date = date.value;
  time = time.value;
  if (date == '') {
    if (isStarter)
      return defaultStartDate;
    return defaultEndDate;
  }
  else {
    let result = new Date(date);
    if (time == '') {
      if (isStarter) {
        result.setHours(0);
        result.setMinutes(0);
      }
      else {
        result.setHours(23);
        result.setMinutes(59);
      }
      return result;
    }
    let pair = time.split(':');
    result.setHours(pair[0]);
    result.setMinutes(pair[1]);
    return result;
  }
}

function setStartDate(date: Date) {
  (dates.startDate as HTMLInputElement).value = MessageManager.startDateLabel;
  (dates.startTime as HTMLInputElement).value = MessageManager.startTimeLabel;
}

function setEndDate(date: Date) {
  (dates.endDate as HTMLInputElement).value = MessageManager.endDateLabel;
  (dates.endTime as HTMLInputElement).value = MessageManager.endTimeLabel;
}

let img = document.createElement('img');
img.src = gif;
img.style.position = 'absolute';
img.style.zIndex = '9999999';
img.style.display = 'none';
img.style.left = '40vw';
img.style.width = '20vw';

img.style.top = '37vh';
img.style.height = '26vh';

let root: HTMLDivElement;

function dateOnChange(event: React.ChangeEvent) {

  let input = event.target as HTMLInputElement;
  if (input.id.includes('start')) {
    MessageManager.startDateLabel = dates.startDate.value;
    MessageManager.startTimeLabel = dates.startTime.value;
    MessageManager.startDate = getDateTimeByString(dates.startDate, dates.startTime, true);
  } else {
    MessageManager.endDateLabel = dates.endDate.value;
    MessageManager.endTimeLabel = dates.endTime.value;
    MessageManager.endDate = getDateTimeByString(dates.endDate, dates.endTime, false);
  }

  if (MessageManager.startDate.toISOString() != defaultStartDate.toISOString() && MessageManager.endDate.toISOString() != defaultEndDate.toISOString()) {
    dates.endDate.disabled = true;
    dates.endTime.disabled = true;
    dates.startTime.disabled = true;
    dates.startDate.disabled = true;
    img.style.display = 'block';
    (root.firstChild as HTMLElement).style.opacity = '0.5';
    (root.firstChild as HTMLElement).style.pointerEvents = 'none';
    setHistory(true);
  }
  else if (MessageManager.startDate.toISOString() == defaultStartDate.toISOString() && MessageManager.endDate.toISOString() == defaultEndDate.toISOString()) {
    setHistory(false);
  }
  else {
  }
  if (!isRealTime) {
    OnDateChange().then(() => {
      dates.endDate.disabled = false;
      dates.endTime.disabled = false;
      dates.startTime.disabled = false;
      dates.startDate.disabled = false;
      (root.firstChild as HTMLElement).style.opacity = '1';
      (root.firstChild as HTMLElement).style.pointerEvents = 'auto';
      img.style.display = 'none';
    });
  } else {
    MessageManager.UpdatingDisplayOfRecords();
  }
}

export class SlpData extends React.Component {
  constructor(props: any) {
    super(props);
  }
  componentWillUnmount() {
    root.removeChild(img);
  }
  componentDidMount() {
    root = document.getElementById('root') as HTMLDivElement;
    root.appendChild(img);
    setHistory(false);
    if (MessageManager.startDate.toISOString() != defaultStartDate.toISOString()) {
      setHistory(true);
      setStartDate(MessageManager.startDate);
    }
    if (MessageManager.endDate.toISOString() != defaultEndDate.toISOString())
      setEndDate(MessageManager.endDate);
    setLineBreak(MessageManager.lineBreak);
    launch();
    MessageManager.UpdatingDisplayOfRecords();
  }
  showSlpDataPanelButtonOnclick() {
    if (slpDataPanel.style.display != 'none') {
      slpDataPanel.style.display = 'none';
    }
    else {
      slpDataPanel.style.display = 'initial';
    }
  }
  render() {
    return (
      <div className={style.root} ref={ref => root = ref as HTMLDivElement}>
        <button id="show_slp_data_panel_button" onClick={this.showSlpDataPanelButtonOnclick}>≡</button>
        <div id="slp_data_area_fix" ref={ref => textArea = ref as HTMLDivElement}></div>
        <div id="slp_data_panel" className={style.slp_data_panel} ref={ref => slpDataPanel = ref as HTMLDivElement}>
          <h3 ref={ref => realTimeLabel = ref as HTMLHeadingElement}>Реальное время</h3>
          <p>Количество записей</p>
          <input type="text" id="sdp_amount_of_records_to_display_textinput" defaultValue={MessageManager.maxRecordsOnScreen} onChange={
            function (event: React.ChangeEvent) {
              let input = event.target as HTMLInputElement;
              MessageManager.maxRecordsOnScreen = Number(input.value);
              MessageManager.UpdatingDisplayOfRecords();
            }
          } />
          <br />
          <br />
          <div>
            <input type="checkbox" id="sdp_autoscroll_checkbox" defaultChecked={MessageManager.autoscrollingIsEnabled} onChange={
              function (event: React.ChangeEvent) {
                let input = event.target as HTMLInputElement;
                MessageManager.autoscrollingIsEnabled = input.checked;
              }
            } /> автопрокрутка < br />
          </div>
          <br />
          <button id="sdp_clear_button" onClick={
            function () {
              MessageManager.allRecords = [];
              MessageManager.UpdatingDisplayOfRecords();
            }
          } > Очистить</button>
          <hr />
          <h3 ref={ref => historyLabel = ref as HTMLHeadingElement}>История</h3>
          <p>Начальное время</p>
          <div>
            <input id="sdp_history_start_date" type="date" ref={ref => dates.startDate = ref as HTMLInputElement} onChange={dateOnChange} />
            <input id="sdp_history_start_time" type="time" ref={ref => dates.startTime = ref as HTMLInputElement} onChange={dateOnChange} defaultValue="00:00"/>
          </div>
          <p>Конечное время</p>
          <div>
            <input id="sdp_history_end_date" type="date" ref={ref => dates.endDate = ref as HTMLInputElement} onChange={dateOnChange} />
            <input id="sdp_history_end_time" type="time" ref={ref => dates.endTime = ref as HTMLInputElement} onChange={dateOnChange} defaultValue="23:59"/>
          </div>
          <br />
          <button id="sdp_show_now" onClick={
            function () {
              MessageManager.startDate = defaultStartDate;
              MessageManager.endDate = defaultEndDate;
              dates.setToDefault();
              setHistory(false);
              MessageManager.UpdatingDisplayOfRecords();
            }
          }>Сейчас</button>
          <hr />
          <h3>Фильтр</h3>
          <p>Номера БС</p>
          <input type="text" id="sdp_filter_bs_nums_textinput" defaultValue={MessageManager.bsIdFilterLabel} />
          <p>Номера устройств</p>
          <input type="text" id="sdp_filter_epd_nums_textinput" defaultValue={MessageManager.auIdFilterLabel} />
          <br />
          <br />
          <table>
            <tbody>
              <tr>
                <td><input type="checkbox" id="show_su_records_checkbox" /> SU<br /></td>
                <td><input type="checkbox" id="show_telemetry_records_checkbox" /> Телеметрия<br /></td>
              </tr>
              <tr>
                <td><input type="checkbox" id="show_lbs_records_checkbox" /> LBS<br /></td>
                <td><input type="checkbox" id="show_modem_records_checkbox" ref={ref => modemCheckBox = ref as HTMLInputElement} /> Модем<br /></td>
              </tr>
              <tr>
                <td><input type="checkbox" id="show_lbsm_records_checkbox" ref={ref => lbsmCheckBox = ref as HTMLInputElement} /> LBSM<br /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h3>Прочее</h3>
          <div>
            <input type="checkbox" id="line_break_checkbox" defaultChecked={MessageManager.lineBreak} onChange={
              function (event: React.ChangeEvent) {
                setLineBreak((event.target as HTMLInputElement).checked);
              }
            } /> перенос строк<br />
          </div>
          <br />
        </div>
      </div>);
  }
}
export { isRealTime };