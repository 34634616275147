import * as React from 'react';
import rubbishBin from '../../../img/rubbish bin.png';

let keyArray: number[] = [];

function getKey() {
    let value = 0;
    while (keyArray.includes(value)) {
        value = Math.random() * 10000;
    }
    keyArray.push(value);
    return value;
}

export const REMOVE_SERVICE_FILE_EVENT_NAME = 'receivedRemoveRequest';

export interface RemoveServiceFileEventProps {
    id: number
}

function formatDate(dateTime: Date) {
    let all = dateTime.toString().split(/-|:|[T]|[.]/);
    return `${all[2]}.${all[1]}.${all[0]} / ${all[3]}:${all[4]}`;
}
class ServiceFile extends React.Component<{ serviceFile: any }> {
    constructor(props: any) {
        super(props);
        this.onRemoveClick = this.onRemoveClick.bind(this);
    }
    onRemoveClick() {
        window.dispatchEvent(new CustomEvent<RemoveServiceFileEventProps>(REMOVE_SERVICE_FILE_EVENT_NAME, { detail: { id: this.props.serviceFile.number } }))
    }
    render() {
        return (
            <tr>
                <td>{this.props.serviceFile.name}</td>
                <td>{formatDate(this.props.serviceFile.addTime)}</td>
                <td>{this.props.serviceFile.note}</td>
                <td>{this.props.serviceFile.size}</td>
                <td>{this.props.serviceFile.type}</td>
                <td>{this.props.serviceFile.version}</td>
                <td><button>Отправить</button></td>
                <td><button onClick={this.onRemoveClick}><img src={rubbishBin} /></button></td>
            </tr>
        );
    }
}
let browseFileInput: HTMLInputElement;
let serviceFileReader = new FileReader();
let serviceFileName = '';

function readServiceFile(input: any) {
    let file = input.target.files[0];
    serviceFileName = input.target.files[0].name;
    serviceFileReader.readAsArrayBuffer(file);
    serviceFileReader.onerror = function () {
        console.log(serviceFileReader.error);
    };
}

let servicePackNameInput: HTMLInputElement;
let servicePackTypeSelect: HTMLSelectElement;
let servicePackVersionInput: HTMLInputElement;

export interface DownloadServiceFileProps {
    servicePackName: string,
    serviceFileName: string,
    servicePackType: number,
    servicePackVer: number,
    data: number[]
}

function downloadFileButtonOnclick() {
    let servicePackName = servicePackNameInput.value;
    if (servicePackName.replace(/\s+/g, '') === '') {
        alert('Введите корректное имя пакета.');
        return;
    }
    let servicePackType = servicePackTypeSelect.selectedIndex + 1;
    let servicePackVer = Number(servicePackVersionInput.value);
    if (servicePackVer > 65536 || servicePackVer < 0) {
        alert('Введите корректную версию пакета (0 - 65536).');
        return;
    }

    let buff = new Uint8Array(serviceFileReader.result as ArrayBuffer);
    if (buff.byteLength < 1) {
        alert('Выберите файл для загрузки.');
        return;
    }
    if (buff.byteLength > 10 * 1024 * 1024) {
        alert('Размер файла не должен превышать 10 Мбайт.');
        return;
    }
    let arr = [buff.byteLength];
    for (let i = 0; i < buff.byteLength; i++) {
        arr[i] = buff[i];
    }

    window.dispatchEvent(new CustomEvent<DownloadServiceFileProps>(DOWNLOAD_SERVICE_FILE_EVENT_NAME, {
        detail: { data: arr, serviceFileName: serviceFileName, servicePackName: servicePackName, servicePackType: servicePackType, servicePackVer: servicePackVer }
    }));

    servicePackNameInput.value = '';
    servicePackTypeSelect.selectedIndex = 0;
    browseFileInput.value = '';
    serviceFileReader = new FileReader();
}
export const DOWNLOAD_SERVICE_FILE_EVENT_NAME = 'receivedServiceFile';

function getElementsByServiceFilesArray(serviceFiles: any[]) {
    let elements: JSX.Element[] = [];
    for (let file of serviceFiles) {
        elements.push(<ServiceFile serviceFile={file} key={ getKey() }/>);
    }
    return elements;
}
class Body extends React.Component<{ serviceFiles: any[] }> {
    render() {
        return <>
            <br />
            <h3>Управление сервисными данными</h3>
            <br />
            <div id="div_with_service_pack_table">
                <table id="table_with_service_pack">
                    <thead>
                        <tr>
                            <th>Имя</th>
                            <th>Дата загрузки</th>
                            <th>Имя файла</th>
                            <th>Размер</th>
                            <th>Тип</th>
                            <th>Версия</th>
                            <th>Отправить на БС</th>
                            <th>Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getElementsByServiceFilesArray(this.props.serviceFiles)}
                    </tbody>
                </table>
            </div>
            <div id="div_add_new_service_pack">
                <hr />
                <span>Имя</span>
                <input type="text" id="input_service_file_name" ref={ref => servicePackNameInput = ref as HTMLInputElement} />
                <span>Тип</span>
                <select id="select_service_file_type" ref={ref => servicePackTypeSelect = ref as HTMLSelectElement}>
                    <option>EPO</option>
                    <option>Прошивка SU</option>
                    <option>Прошивка TS</option>
                </select>
                <span>Версия</span>
                <input type="number" id="input_service_file_version" ref={ref => servicePackVersionInput = ref as HTMLInputElement} />
                <br />
                <input id="browse_service_file_button" onChange={readServiceFile} type="file" ref={ref => browseFileInput = ref as HTMLInputElement} />
                <button id="download_service_file_button" onClick={downloadFileButtonOnclick}>Загрузить на сервер</button>
            </div>
        </>;
    }
}

export { Body as ServiceData };