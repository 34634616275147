import RecordTypes from './record_types';

function fromUTC (date:Date) {
    return new Date(date.toUTCString().substr(0, 25))
}

const SuMessageTypes = {
    normal_msg: 'normal_msg·',
    imp_sens: 'shock_sens·',
    fall_detect: 'fall_detect',
    acc_both: 'accel_both·',
    reg_request: 'reg_request',
    bs_alarm: 'alarm_by_bs',
    fw_change: 'fw_changing',
    fw_version: 'fw_version·',
    reg_confirm: 'reg_confirm',
    alarm_btn: 'alarm_btn··',
    alarm_acc: 'alarm_accel',
    alarm_res: 'alarm_reset',
    reg_res_confirm: 'reg_cleared',
    full_su: 'full_msg···',
    reduced_su: 'reduced_msg',
    no_su: 'no_message·',
    accel_ok: 'accel_ok··'
};

let isEven = false;
class Record {
    static textArea: HTMLDivElement;
    div: HTMLDivElement;
    type: number;
    bsId: number;
    epdId: number;
    time:Date;
    htmlItems: HTMLElement[];
    constructor(record: any) {
        this.div = document.createElement('div');
        this.type = record.recordType;
        this.bsId = record.bsId;
        this.epdId = 0;
        this.time = new Date(record.serverReceivingTimeStamp);
        this.htmlItems = [];
        this.htmlItems[0] = document.createElement('span');
        this.htmlItems[0].classList.add('slp-data-record-time');
        let timeStr = `[${record.serverReceivingTimeStamp}`.replace('T', ' ');
        timeStr = timeStr.slice(0, 24);
        while (timeStr.length < 24) timeStr += '0';
        timeStr += '] ';
        this.htmlItems[0].textContent = timeStr;
        this.htmlItems[1] = document.createElement('span');
        this.htmlItems[1].classList.add('slp-data-record-bs-number');
        this.htmlItems[1].textContent = ` ${record.bsId}: `;
        // Выравнивает значения по количеству символов (по типу su ···12).
        let GetAlignedValue = (value: number, count:number) => {
            let offset = -1;
            let line = '';
            for (let i = 0; i < count; i++) {
                offset--;
                if (i < count - 1)
                    line += '·';
            }
            return `${line}${value}·`.slice(offset, -1);
        }

        let AddIuDatas = (iu: any[]) => {
            for (let i = 0; i < iu.length; i++) {
                AddSegmentToRecord(' iu ', GetAlignedValue(iu[i].id, 5));
                AddSegmentToRecord(' - rssi ', GetAlignedValue(iu[i].rssi, 4));
                AddSegmentToRecord(' - nois ', GetAlignedValue(iu[i].nois, 4));
                //if (j != iu.length - 1)
                AddSegmentToRecord(' | ', null);
            }
        }

        let GetAlignedBool = (value: boolean) => {
            if (value)
                return 'true·';
            return 'false';
        }

        let AddSegmentToRecord = (name: any, value: any) => {
            let currentArrPosition = this.htmlItems.length;
            this.htmlItems[currentArrPosition] = document.createElement('span');
            this.htmlItems[currentArrPosition].classList.add('slp-data-val-name');
            this.htmlItems[currentArrPosition].textContent = name;
            currentArrPosition++;
            this.htmlItems[currentArrPosition] = document.createElement('span');
            this.htmlItems[currentArrPosition].classList.add('slp-data-val');
            this.htmlItems[currentArrPosition].textContent = value;
        };

        let AddSuMsgTypeSegmentToRecord = (suMessageType: any) => {
            this.htmlItems[this.htmlItems.length] = document.createElement('span');
            this.htmlItems[this.htmlItems.length - 1].classList.add('slp-data-record-bs-number');
            this.htmlItems[this.htmlItems.length - 1].textContent = ` ${suMessageType} `;
        };

        let makeTimeSymbol = (time: any) => { return (time < 10) ? `0${time}` : `${time}`; };

        let finishToTwodDigitValue = (val: number) => {
            if (val < 10) return `0${val}`;
            else return `${val}`;
        };

        let makeheightChangeModuleStr = (heightChangeModuleVal: any) => {
            if (heightChangeModuleVal === 0) return '0..2.9';
            if (heightChangeModuleVal === 1) return '3..5.9';
            if (heightChangeModuleVal === 2) return '6..8.9';
            if (heightChangeModuleVal === 3) return '9..11.9';
            if (heightChangeModuleVal === 4) return '12..14.9';
            if (heightChangeModuleVal === 5) return '15..18';
            if (heightChangeModuleVal === 6) return '>18';
        };

        if (record.recordType === RecordTypes.Su) {
            this.epdId = record.data.id;
            AddSegmentToRecord(' su ', `····${record.data.id}·`.slice(-6, -1));
            let bsReceivingTimeStr = `${record.bsReceivingTimeStamp}`.replace('T', ' ');
            bsReceivingTimeStr = bsReceivingTimeStr.slice(10, 24);
            while (bsReceivingTimeStr.length < 13) bsReceivingTimeStr += '0';
            AddSegmentToRecord(' rcvTs ', `${bsReceivingTimeStr}`);
            AddSegmentToRecord(' - p ', `${record.data.version}`);
        }

        if (record.recordType === RecordTypes.DeviceRegistrationRequest || record.recordType === RecordTypes.DeviceFirmwareVersion ||
            record.recordType === RecordTypes.ConfirmDeviceRegistration) {
            this.epdId = record.data.deviceId;
            AddSegmentToRecord(' su ', `····${record.data.deviceId}·`.slice(-6, -1));
            let bsReceivingTimeStr = `${record.bsReceivingTimeStamp}`.replace('T', ' ');
            bsReceivingTimeStr = bsReceivingTimeStr.slice(10, 24);
            while (bsReceivingTimeStr.length < 13) bsReceivingTimeStr += '0';
            AddSegmentToRecord(' rcvTs ', `${bsReceivingTimeStr}`);
            AddSegmentToRecord(' - p ', '3');
        }

        if (record.recordType === RecordTypes.Su) {
            // SU v1 Обычное сообщение.
            if (record.data.version == 1) {
                AddSuMsgTypeSegmentToRecord(SuMessageTypes.normal_msg);
                AddSegmentToRecord('bat ', `${finishToTwodDigitValue(record.data.bat)}%`);
                AddSegmentToRecord(' - fixTs ', `${makeTimeSymbol(record.data.hour)}:${makeTimeSymbol(record.data.minute)}:${makeTimeSymbol(record.data.second)}`);
                let latitude = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 6, minimumFractionDigits: 6 }).format(record.data.latitude);
                let longitude = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 6, minimumFractionDigits: 6 }).format(record.data.longitude);
                AddSegmentToRecord(' - la-ln ', `${latitude} ${longitude}`);
                AddSegmentToRecord(' - sat ', `${finishToTwodDigitValue(record.data.numberSatellites)}`);
                AddSegmentToRecord(' - fix ', `${record.data.fixQuality}`);
                let pressure = new Intl.NumberFormat('En-en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(record.data.pressure);
                AddSegmentToRecord(' - press ', `${pressure}`);
                AddSegmentToRecord(' - acc_x ', `${record.data.accelerometerX}`);
                AddSegmentToRecord(' - acc_y ', `${record.data.accelerometerY}`);
                AddSegmentToRecord(' - acc_z ', `${record.data.accelerometerZ}`);
                AddSegmentToRecord(' - rssi ', `${record.data.receivingRssi}`);
                AddSegmentToRecord(' - nois ', `${record.data.receivingNois}`);
                this.htmlItems[this.htmlItems.length] = document.createElement('br');
                return;
            }

            // SU v2 и v3 Обычное сообщение, первая часть.
            if (record.data.version == 2 || record.data.version == 3) {
                let acc_sign = record.data.accelerometerSignal;
                let add_mesg = record.data.additionalMessage;
                let alarm_ty = record.data.alarm;
                if (acc_sign === 0 && add_mesg === 0 && alarm_ty === 0) AddSuMsgTypeSegmentToRecord(SuMessageTypes.normal_msg);
                if (acc_sign === 1) AddSuMsgTypeSegmentToRecord(SuMessageTypes.imp_sens);
                if (acc_sign === 2) AddSuMsgTypeSegmentToRecord(SuMessageTypes.fall_detect);
                if (acc_sign === 3) AddSuMsgTypeSegmentToRecord(SuMessageTypes.acc_both);
                if (add_mesg === 3) AddSuMsgTypeSegmentToRecord(SuMessageTypes.bs_alarm);
                if (add_mesg === 4) AddSuMsgTypeSegmentToRecord(SuMessageTypes.fw_change);
                if (add_mesg === 10) AddSuMsgTypeSegmentToRecord(SuMessageTypes.alarm_res);
                if (add_mesg === 11) AddSuMsgTypeSegmentToRecord(SuMessageTypes.reg_res_confirm);
                if (alarm_ty === 1) AddSuMsgTypeSegmentToRecord(SuMessageTypes.alarm_btn);
                if (acc_sign == 0 && alarm_ty === 2) AddSuMsgTypeSegmentToRecord(SuMessageTypes.alarm_acc);
                AddSegmentToRecord('bat ', `${finishToTwodDigitValue(record.data.bat)}%`);
                AddSegmentToRecord(' - fixTs ', `${makeTimeSymbol(record.data.hour)}:${makeTimeSymbol(record.data.minute)}:${makeTimeSymbol(record.data.second)}`);
                let latitude = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 6, minimumFractionDigits: 6 }).format(record.data.latitude);
                let longitude = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 6, minimumFractionDigits: 6 }).format(record.data.longitude);
                AddSegmentToRecord(' - la-ln ', `${latitude} ${longitude}`);

                AddSegmentToRecord(' - sat ', `${finishToTwodDigitValue(record.data.numberSatellites)}`);
                AddSegmentToRecord(' - fix ', `${record.data.fixQuality}`);
                let hdop = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(record.data.hdop);
                AddSegmentToRecord(' - hdop ', `${hdop}`);
                let course = new Intl.NumberFormat('En-en', { maximumFractionDigits: 3, minimumIntegerDigits: 3 }).format(record.data.azimuth);
                AddSegmentToRecord(' - course ', `${course}`);
                let speed = '';
                if (record.data.speed < 100) speed = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 2, minimumFractionDigits: 1 }).format(record.data.speed);
                else speed = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 3, maximumFractionDigits: 3, minimumFractionDigits: 1 }).format(record.data.speed);
                AddSegmentToRecord(' - speed ', `${speed}`);
                let pressure = new Intl.NumberFormat('En-en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(record.data.pressure);
                AddSegmentToRecord(' - press ', `${pressure}`);
                let temperature = new Intl.NumberFormat('EN-en', { minimumIntegerDigits: 2, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(record.data.temperature);
                AddSegmentToRecord(' - temp ', `${temperature}`);
                AddSegmentToRecord(' - activ ', `${record.data.levelOfMotorActivity}`);
            }

            // SU v2 Обычное сообщение, вторая часть.
            if (record.data.version == 2) {
                AddSegmentToRecord(' - rssi ', `${record.data.receivingRssi}`);
                AddSegmentToRecord(' - nois ', `${record.data.receivingNois}`);
                this.htmlItems[this.htmlItems.length] = document.createElement('br');
                return;
            }

            // SU v3 Обычное сообщение, вторая часть.
            if (record.data.version == 3) {
                AddSegmentToRecord(' - in_room ', `${GetAlignedBool(record.data.inRoom)}`);
                AddSegmentToRecord(' - height_ch ', makeheightChangeModuleStr(record.data.heightChangeModule));
                AddSegmentToRecord(' - rssi ', `${record.data.receivingRssi}`);
                AddSegmentToRecord(' - nois ', `${record.data.receivingNois}`);
                this.htmlItems[this.htmlItems.length] = document.createElement('br');
                return;
            }
        }

        if (record.recordType === RecordTypes.Lbs) {
            this.epdId = record.data.id;
            if (record.data.id < 10000) AddSegmentToRecord(' lbs ', `···${record.data.id}·`.slice(-5, -1));
            else AddSegmentToRecord(' lbs ', `${record.data.id}`);
            let bsReceivingTimeStr = `${record.bsReceivingTimeStamp}`.replace('T', ' ');
            bsReceivingTimeStr = bsReceivingTimeStr.slice(10, 24);
            while (bsReceivingTimeStr.length < 13) bsReceivingTimeStr += '0';
            // AddSegmentToRecord(` rcvTs `, `${bsReceivingTimeStr}`);
            AddSegmentToRecord(' - p ', '3');
            AddSuMsgTypeSegmentToRecord(SuMessageTypes.normal_msg);
            AddSegmentToRecord(' rssi ', `${record.data.receivingRssi}`);
            AddSegmentToRecord(' - nois ', `${record.data.receivingNois}`);
            AddSegmentToRecord(' - thr_rssi ', `${record.data.thresholdRssi}`);
            let strWithSuIds = ' ';
            for (let c of record.data.suId) {
                strWithSuIds += `${c}, `;
            }
            AddSegmentToRecord(' - su_ids: ', strWithSuIds.slice(0, -2));
            this.htmlItems[this.htmlItems.length] = document.createElement('br');
            return;
        }

        if (record.recordType === RecordTypes.Lbsm) {
            let full = record.data.fullSuList;
            let reduced = record.data.reducedSuList;
            AddSegmentToRecord(' lbsm ', `${GetAlignedValue(record.data.id, 3)}`);
            let bsReceivingTimeStr = `${record.bsReceivingTimeStamp}`.replace('T', ' ');
            bsReceivingTimeStr = bsReceivingTimeStr.slice(10, 23);
            while (bsReceivingTimeStr.length < 13) bsReceivingTimeStr += '0';
            AddSegmentToRecord(' rcvTs ', `${bsReceivingTimeStr}`);
            AddSegmentToRecord(' - p ', 3);
            if (full.length > 0) {
                AddSuMsgTypeSegmentToRecord(SuMessageTypes.full_su);
            }
            else if (reduced.length > 0) {
                AddSuMsgTypeSegmentToRecord(SuMessageTypes.reduced_su);
            }
            else {
                AddSuMsgTypeSegmentToRecord(SuMessageTypes.no_su);
            }
            AddSegmentToRecord(' - alarm ', record.data.alarm);
            AddSegmentToRecord(' - rssi ', GetAlignedValue(record.data.receivingRssi, 4));
            AddSegmentToRecord(' - nois ', GetAlignedValue(record.data.receivingNois, 4));
            this.htmlItems[this.htmlItems.length] = document.createElement('br');

            for (let i = 0; i < full.length; i++) {
                AddSegmentToRecord('····························· su ', GetAlignedValue(full[i].id, 5));
                AddSegmentToRecord(' - activ ', full[i].levelOfMotorActivity);
                AddSegmentToRecord(' - sync ', GetAlignedBool(full[i].syncSign));
                AddSegmentToRecord(' - alarm ', GetAlignedBool(full[i].alarm));
                let line = "";
                if (full[i].accelerometerSignal == 1) {
                    line = SuMessageTypes.imp_sens;
                }
                else if (full[i].accelerometerSignal == 2) {
                    line = SuMessageTypes.fall_detect;
                }
                else if (full[i].accelerometerSignal == 3) {
                    line = SuMessageTypes.acc_both;
                }
                else {
                    line = SuMessageTypes.accel_ok;
                }
                AddSegmentToRecord(` ${line} `, null);
                AddSegmentToRecord(' - press ', GetAlignedValue(full[i].pressure.toFixed(2), 6));
                AddSegmentToRecord(' - temp ', full[i].temperature.toFixed(1));
                AddSegmentToRecord(' - rcv_ts ', full[i].suReceivingTime);
                AddSegmentToRecord(' : ', null);
                this.htmlItems[this.htmlItems.length] = document.createElement('br');
                AddSegmentToRecord('································', null);
                AddIuDatas(full[i].iuList)
                this.htmlItems[this.htmlItems.length] = document.createElement('br');
            }

            for (let i = 0; i < reduced.length; i++) {
                AddSegmentToRecord('····························· su ', `····${reduced[i].id}·`.slice(-6, -1));
                AddSegmentToRecord(' - rcv_ts ', reduced[i].suReceivingTime);
                AddSegmentToRecord(' : ', null);
                AddIuDatas(reduced[i].iuList)
                this.htmlItems[this.htmlItems.length] = document.createElement('br');
            }
            return;
        }

        if (record.recordType === RecordTypes.TelemetryData) {
            let makeTimeSymbol = (time: number) => { return (time < 10) ? `0${time}` : `${time}`; };
            AddSegmentToRecord(' bs_time ', `${makeTimeSymbol(record.data.hour)}:${makeTimeSymbol(record.data.minute)}:${makeTimeSymbol(record.data.second)}`);
            let latitude = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 6, minimumFractionDigits: 6 }).format(record.data.latitude);
            let longitude = new Intl.NumberFormat('En-en', { minimumIntegerDigits: 2, maximumFractionDigits: 6, minimumFractionDigits: 6 }).format(record.data.longitude);
            AddSegmentToRecord(' - la-ln ', `${latitude} ${longitude}`);
            AddSegmentToRecord(' - fix ', `${record.data.fixQuality}`);
            AddSegmentToRecord(' - temp_in ', `${record.data.temperatureInside.toFixed(1)}`);
            AddSegmentToRecord(' - temp_out ', `${record.data.temperatureOutside.toFixed(1)}`);
            AddSegmentToRecord(' - press ', `${record.data.pressure.toFixed(2)}`);
            this.htmlItems[this.htmlItems.length] = document.createElement('br');
            return;
        }

        if (record.recordType === RecordTypes.DeviceRegistrationRequest) {
            AddSuMsgTypeSegmentToRecord(SuMessageTypes.reg_request);
            let deviceType = '';
            if (record.data.deviceType != 3) deviceType = 'SU';
            else deviceType = 'LBS';
            AddSegmentToRecord(' dev_type ', `${deviceType}`);
            AddSegmentToRecord(' - serial_number ', `${record.data.serialNumber}`);
            AddSegmentToRecord(' - site_number ', `${record.data.siteNumber}`);
            this.htmlItems[this.htmlItems.length] = document.createElement('br');
            return;
        }

        if (record.recordType === RecordTypes.DeviceFirmwareVersion) {
            AddSuMsgTypeSegmentToRecord(SuMessageTypes.fw_version);
            let deviceType = '';
            if (record.data.deviceType != 3) deviceType = 'SU';
            else deviceType = 'LBS';
            AddSegmentToRecord(' dev_type ', `${deviceType}`);
            AddSegmentToRecord(' - fixTs ', `${makeTimeSymbol(record.data.hour)}:${makeTimeSymbol(record.data.minute)}:${makeTimeSymbol(record.data.second)}`);
            AddSegmentToRecord(' - firmware_ver ', `${record.data.firmwareVersion}`);
            AddSegmentToRecord(' - site_number ', `${record.data.siteNumber}`);
            AddSegmentToRecord(' - bat ', `${finishToTwodDigitValue(record.data.bat)}%`);
            let temperature = new Intl.NumberFormat('EN-en', { minimumIntegerDigits: 2, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(record.data.temperature);
            AddSegmentToRecord(' - temp ', `${temperature}`);
            this.htmlItems[this.htmlItems.length] = document.createElement('br');
            return;
        }

        if (record.recordType === RecordTypes.ConfirmDeviceRegistration) {
            AddSuMsgTypeSegmentToRecord(SuMessageTypes.reg_confirm);
            let deviceType = '';
            if (record.data.deviceType != 3) deviceType = 'SU';
            else deviceType = 'LBS';
            AddSegmentToRecord(' dev_type ', `${deviceType}`);
            AddSegmentToRecord(' - fixTs ', `${makeTimeSymbol(record.data.hour)}:${makeTimeSymbol(record.data.minute)}:${makeTimeSymbol(record.data.second)}`);
            AddSegmentToRecord(' - serial_number ', `${record.data.serialNumber}`);
            AddSegmentToRecord(' - site_number ', `${record.data.siteNumber}`);
            AddSegmentToRecord(' - bat ', `${finishToTwodDigitValue(record.data.bat)}%`);
            this.htmlItems[this.htmlItems.length] = document.createElement('br');
            return;
        }
    }
    AppendToTextArea() {
        for (let c of this.htmlItems) {
            this.div.appendChild(c);
        }
        if (isEven) {
            this.div.className = 'even';
            isEven = false;
        }
        else {
            this.div.className = 'not_even';
            isEven = true;
        }
        this.div.onclick = () => {
            let range = document.createRange();
            range.selectNode(this.div);
            let selection = window.getSelection();
            if (selection != null) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
        Record.textArea.appendChild(this.div);
    }
    RemoveFromTextArea() {
        try {
            Record.textArea.removeChild(this.div);
        } catch (error) { }
    }
};

export default Record;