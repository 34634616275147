import Record from './record';
import { isRealTime } from '../../Views/slp_data';
import record_types from './record_types';
import { findAllInRenderedTree } from 'react-dom/test-utils';


let startDateDefault = new Date(0);
let endDateDefault = new Date(3000, 1, 1, 1, 1, 1, 1);

class MessageManager {
  static recordTypesVals: number[] = [1, 2, 3, 4, 5, 6, 7, 8];
  static lineBreak: boolean = false;
  static textArea: HTMLDivElement;
  static autoscrollingIsEnabled = false;
  static lastMessageId = 0;
  static allRecords: Record[] = [];
  static visibleRecords: Record[] = [];
  static startDate = startDateDefault;
  static endDate = endDateDefault;
  static amountRecordsOnScreen = 0;
  static filteredRecType = new Set<number>(MessageManager.recordTypesVals);
  static filteredBs = new Set<number>();
  static filteredEpdId = new Set<number>();
  static maxRecordsOnScreen = 100;
  static bsIdFilterLabel = "";
  static auIdFilterLabel = "";
  static startDateLabel = "";
  static startTimeLabel = "";
  static endDateLabel = "";
  static endTimeLabel = "";
  static AddRecord(newRecord: Record) {
    MessageManager.allRecords.push(newRecord);
  }
  static AppendRecordToTextArea(newRecord: Record) {
    newRecord.AppendToTextArea();
    MessageManager.visibleRecords.push(newRecord);
  }
  static isMatch(record: Record) {
    if (
      (MessageManager.filteredBs.size == 0 || MessageManager.filteredBs.has(record.bsId)) &&
      (record.type != record_types.Su || MessageManager.filteredEpdId.size == 0 || MessageManager.filteredEpdId.has(record.epdId)) &&
      (MessageManager.filteredRecType.size == 0 || MessageManager.filteredRecType.has(record.type)) &&
      MessageManager.startDate <= record.time &&
      MessageManager.endDate >= record.time
    )
      return true;
    return false;
  }
  static UpdatingDisplayOfRecords() {
    /*
    for (let c of MessageManager.allRecords) {
        c.RemoveFromTextArea();
    }*/
    MessageManager.textArea.innerHTML = '';
    MessageManager.visibleRecords = [];
    if (isRealTime) {
      for (let i = MessageManager.allRecords.length - 1; i >= 0; i--) {
        let record = MessageManager.allRecords[i];
        if (this.isMatch(record)) {
          if (MessageManager.visibleRecords.push(record) >= MessageManager.maxRecordsOnScreen) break;
        }
      }
      MessageManager.visibleRecords = MessageManager.visibleRecords.reverse();
    } else {
      for (let rec of MessageManager.allRecords) {
        if (this.isMatch(rec)) {
          if (MessageManager.visibleRecords.push(rec) >= MessageManager.maxRecordsOnScreen) break;
        }
      }
    }
    for (let rec of MessageManager.visibleRecords) rec.AppendToTextArea();
    MessageManager.amountRecordsOnScreen = MessageManager.visibleRecords.length;
    if (MessageManager.amountRecordsOnScreen == 0 && !isRealTime)
      alert('Ничего не найдено.');
  }
  static SetNewBsNumsForFiltering(str: string) {
    MessageManager.filteredBs.clear();
    this.bsIdFilterLabel = str;
    let arr = str.split(',');
    for (let c of arr) {
      if (c.includes('-')) {
        let [start, end] = c.split('-');
        if (Number.isInteger(start) && Number.isInteger(end) && end >= start) {
          for (let i = Number(start); i <= Number(end); i++) {
            MessageManager.filteredBs.add(i);
          }
        }
      }
      else {
        MessageManager.filteredBs.add(Number(c));
      }
    }
    // Если в MessageManager.filteredBs у нас только один элемент со значением 0, то его надо убирать т.к. пустой набор является флагом того, что фильтр в истории отключён.
    if (MessageManager.filteredBs.size == 1) {
      if (MessageManager.filteredBs.has(0)) MessageManager.filteredBs.clear();
    }
  }
  static SetNewEpdIdForFiltering(str: string) {
    MessageManager.filteredEpdId.clear();
    this.auIdFilterLabel = str;
    let arr = str.split(',');
    for (let c of arr) {
      if (c.includes('-')) {
        let [start, end] = c.split('-');
        if (Number.isInteger(start) && Number.isInteger(end) && end >= start) {
          for (let i = Number(start); i <= Number(end); i++) {
            MessageManager.filteredEpdId.add(i);
          }
        }
      }
      else {
        MessageManager.filteredEpdId.add(Number(c));
      }
    }
    // Если в MessageManager.filteredEpdId у нас только один элемент со значением 0, то его надо убирать т.к. пустой набор является флагом того, что фильтр в истории отключён.
    if (MessageManager.filteredEpdId.size == 1) {
      if (MessageManager.filteredEpdId.has(0)) MessageManager.filteredEpdId.clear();
    }
  }
  static SetNewDatesForFiltring(date: Date, isStarter: boolean) {
    if (isStarter) {
      MessageManager.startDate = date;
    } else {
      MessageManager.endDate = date;
    }
  }
  static AddRecordTypeIntoFilter(recordType: number) {
    if (!MessageManager.filteredRecType.has(recordType)) {
      MessageManager.filteredRecType.add(recordType);
    }
  }
  static RemoveRecordTypeFromFilter(recordType: number) {
    if (MessageManager.filteredRecType.has(recordType)) {
      MessageManager.filteredRecType.delete(recordType);
    }
  }
}

export default MessageManager;

