import * as L from 'leaflet';
import * as signalR from '@microsoft/signalr';
import record_types from '../SlpData/record_types';
import markerIco from '../../../img/marker.png';

let baseStations: Map<any, any>;
let mymap: L.Map;

const realTime = 'Реальное время';
const hist = 'История'

const icon = L.icon({
  iconUrl: markerIco,
  iconSize: [60, 60], // size of the icon
  // iconAnchor - смещение картинки иконки.
  // первый горизонталь чем меньше тем восточнее, чем больше тем западнее.
  // второй вертикаль чем меньше тем южнее, чем больше тем севернее.
  iconAnchor: [30, 60],
  // popupAnchor - смещение надписи.
  popupAnchor: [0, -30]
});

let History = {
  turnOn: false
}

// --------- EndpointDevices ---------
class EndpointDevice {
  isRealtime;
  id;
  color;
  lastLatLng: any[];
  lastPoint;
  points: any[];
  lines: any[];
  isVisible;
  constructor(modelObj: any, isRealtime = true) {
    this.isRealtime = isRealtime;
    this.id = modelObj.id;
    this.color = Math.floor(Math.random() * 0xFFFFFF);
    this.lastLatLng = [modelObj.mapPoints[0].latitude, modelObj.mapPoints[0].longtitude];
    this.lastPoint = this.createPointBody(modelObj.mapPoints[0]);
    this.lastPoint.addTo(mymap);
    this.lastPoint.bindPopup(this.createPopupMessage(modelObj));
    this.points = [this.lastPoint];
    this.lines = [];
    this.isVisible = true;
    if (History.turnOn && !isRealtime) {
      this.isVisible = false;
    }
    if (MapManager.Filter.turnOn && !MapManager.Filter.filteredEpds.has(this.id)) {
      this.isVisible = false;
    }
    for (let c of modelObj.mapPoints) {
      this.addNewPoint(modelObj, c);
    }
  }

  createPopupMessage(modelObj: any, point = modelObj.mapPoints[0]) {
    let navTimeStr = `${point.navSolutionTimeStamp}`.replace('T', ' ');
    let bsTimeStr = `${point.bsReceivingTimeStamp}`.replace('T', ' ');
    let serverTimeStr = `${point.serverReceivingTimeStamp}`.replace('T', ' ');
    bsTimeStr = bsTimeStr.slice(0, 19);
    serverTimeStr = serverTimeStr.slice(0, 19);
    let getAlarmMessage = (val: number) => {
      switch (val) {
        case 0: return 'нет';
        case 1: return 'с кнопки';
        case 2: return 'с акселерометра';
        case 3: return 'сброшена';
        default: return 'неизвестное значение';
      }
    };
    let getAccelerationOnImpactMessage = (val: any) => {
      switch (val) {
        case 0: return 'нет';
        case 1: return 'удар';
        case 2: return 'падение';
        case 3: return 'удар + падение';
        default: return 'неизвестное значение';
      }
    };
    let popupMessage = '<pre>';
    popupMessage += `Тип                    ${modelObj.type}<br/>`;
    popupMessage += `id                     ${modelObj.id}<br/>`;
    popupMessage += `Время нав. решения     ${navTimeStr}<br/>`;
    popupMessage += `Время приёма БС        ${bsTimeStr}<br/>`;
    popupMessage += `Время приёма на сервер ${serverTimeStr}<br/>`;
    popupMessage += `Широта                 ${point.latitude}<br/>`;
    popupMessage += `Долгота                ${point.longtitude}<br/>`;
    popupMessage += `Батарея                ${point.bat}%<br/>`;
    popupMessage += `Регистрация            ${modelObj.owner}<br/>`;
    popupMessage += `Тревога                ${getAlarmMessage(point.alarm)}<br/>`;
    popupMessage += `Кол. спутников         ${point.numberSatellites}<br/>`;
    popupMessage += `Фикс                   ${point.fixQuality}<br/>`;
    popupMessage += `HDOP                   ${point.hdop}<br/>`;
    popupMessage += `Азимут                 ${point.azimuth}<br/>`;
    popupMessage += `Скорость               ${point.speed} км/ч<br/>`;
    popupMessage += `Давление               ${point.pressure} гПа<br/>`;
    popupMessage += `Температура            ${point.temperature} °C<br/>`;
    popupMessage += `Активность             ${point.levelOfMotorActivity}<br/>`;
    popupMessage += `В помещении            ${point.inRoom ? 'да' : 'нет'}<br/>`;
    popupMessage += `Изменение высоты       ${point.heightChange}<br/>`;
    popupMessage += `Трев. по акселерометру ${getAccelerationOnImpactMessage(point.accelerationOnImpact)}<br/>`;
    popupMessage += `Уровень сигнала        ${point.receivingRssi} дБм<br/>`;
    popupMessage += `Сигнал/шум             ${point.receivingNois} дБ</pre>`;
    return popupMessage;
  }

  addNewPoint(modelObj: any, newPoint: any) {
    if (MapManager.Tracker.turnOn === false) {
      this.lastPoint.remove();
      let nextPoint = this.createPointBody(newPoint);
      if (this.isVisible) {
        nextPoint.addTo(mymap);
      }
      nextPoint.bindPopup(this.createPopupMessage(modelObj, newPoint));
      this.lastPoint = nextPoint;
      this.points.push(nextPoint);
      let line = L.polyline([this.lastLatLng, [newPoint.latitude, newPoint.longtitude]], { color: `#${this.color.toString(16)}` });
      this.lines.push(line);
      this.lastLatLng = [newPoint.latitude, newPoint.longtitude];
    } else {
      let nextPoint = this.createPointBody(newPoint);
      if (this.isVisible) {
        nextPoint.addTo(mymap);
      }
      nextPoint.bindPopup(this.createPopupMessage(modelObj, newPoint));
      this.lastPoint = nextPoint;
      this.points.push(nextPoint);
      let line = L.polyline([this.lastLatLng, [newPoint.latitude, newPoint.longtitude]], { color: `#${this.color.toString(16)}` });
      if (this.isVisible) {
        line.addTo(mymap);
      }
      this.lines.push(line);
      this.lastLatLng = [newPoint.latitude, newPoint.longtitude];
      if (this.points.length > MapManager.Tracker.amountOfPoints) {
        this.points[this.points.length - MapManager.Tracker.amountOfPoints - 1].remove(mymap);
        this.lines[this.points.length - MapManager.Tracker.amountOfPoints - 1].remove(mymap);
      }
    }
  }

  createPointBody(modelMapPoint: any) {
    let circle = L.circle([modelMapPoint.latitude, modelMapPoint.longtitude], {
      color: `#${this.color.toString(16)}`,
      weight: 3,
      fillColor: `#${this.color.toString(16)}`,
      fillOpacity: 0.2,
      radius: 4
    });
    return circle;
  }

  updatePointMessage(circle: any, obj: any, epd: any) {
    circle.setLatLng(new L.LatLng(obj.latitude, obj.longtitude));
    circle._popup.setContent(epd.CreatePopupMessage(obj));
  }

  // Этот метод вызывает трекер, когда он меняет свои параметры. Тут обновляется отображение устройства на карте согласно новым параметрам.
  updateTrackerStatus() {
    for (let c of this.points) c.remove();
    for (let c of this.lines) c.remove();
    if (this.isRealtime) {
      if ((MapManager.Filter.turnOn && !MapManager.Filter.filteredEpds.has(this.id)) || MapManager.EpdsHistory.turnOn) {
        this.isVisible = false;
        return; // Если это устройство фильтруется то отображать не чего не нужно.
      } else {
        this.isVisible = true;
      }
      if (MapManager.Tracker.turnOn) {
        for (let i = this.points.length; (i !== 0) && (this.points.length - i < MapManager.Tracker.amountOfPoints); i--) {
          this.points[i - 1].addTo(mymap);
          if (i !== this.points.length) this.lines[i - 1].addTo(mymap); // Линий на одну меньще чем точек, поэтому if.
        }
      } else {
        this.lastPoint.addTo(mymap);
      }
    } else {
      if (MapManager.EpdsHistory.filteredEpds.size === 0) {
        for (let c of this.points) c.addTo(mymap);
        for (let c of this.lines) c.addTo(mymap);
      } else {
        if (MapManager.EpdsHistory.filteredEpds.has(this.id)) {
          for (let c of this.points) c.addTo(mymap);
          for (let c of this.lines) c.addTo(mymap);
        }
      }

    }
  }

  // Удаляет все свои контролы с карты. Вызывается перед удалением объекта.
  remove() {
    this.isVisible = false;
    for (let c of this.points) c.remove(mymap);
    for (let c of this.lines) c.remove(mymap);
  }
}

class MapManager {
  static circleEnabled = false;

  // Трекер.
  static Tracker = {
    MAX_AMOUNT_OF_POINTS: 1000,
    turnOn: false,
    amountOfPoints: 3
  };
  // Фильтр.
  static Filter = {
    turnOn: false,
    filteredEpds: new Set(),
    filterLabel: "10",
    setNewEpdsForFiltering: (str: string) => {
      MapManager.Filter.filteredEpds.clear();
      let arr = str.split(',');
      for (let c of arr) {
        if (c.includes('-')) {
          let [startstr, endstr] = c.split('-');
          let start = Number(startstr);
          let end = Number(endstr);
          if (Number.isInteger(start) && Number.isInteger(end) && end >= start) {
            for (let i = start; i <= end; i++) {
              MapManager.Filter.filteredEpds.add(i);
            }
          }
        } else {
          MapManager.Filter.filteredEpds.add(Number(c));
        }
      }
    }
  };

  // --------- История ---------

  static EpdsHistory = {
    turnOn: false,
    filterLabel: "",
    startDateLabel: "",
    startTimeLabel: "00:00",
    endDateLabel: "",
    endTimeLabel: "00:00",
    filteredEpds: new Set(),
    endpointDevices: new Map(),
    setNewEpdsForFiltering: (str: string) => {
      MapManager.EpdsHistory.filterLabel = str;
      MapManager.EpdsHistory.filteredEpds.clear();
      let arr = str.split(',');
      for (let c of arr) {
        if (c.includes('-')) {
          let [startstr, endstr] = c.split('-');
          let start = Number(startstr);
          let end = Number(endstr);
          if (Number.isInteger(start) && Number.isInteger(end) && end >= start) {
            for (let i = start; i <= end; i++) {
              MapManager.EpdsHistory.filteredEpds.add(i);
            }
          }
        } else {
          MapManager.EpdsHistory.filteredEpds.add(Number(c));
        }
      }
      // Если в EpdsHistory.filteredEpds у нас только один элемент со значением 0, то его надо убирать т.к. пустой набор является флагом того, что фильтр в истории отключён.
      if (MapManager.EpdsHistory.filteredEpds.size === 1) {
        if (MapManager.EpdsHistory.filteredEpds.has(0)) MapManager.EpdsHistory.filteredEpds.clear();
      }
    }
  };
  static endpointDevices = new Map<number, EndpointDevice>();

  static ChangeCircleEnable(val: boolean) {
    if (this.circleEnabled == val) {
      return;
    }
    this.circleEnabled = val;
    for (let bs of Array.from(baseStations.values())) {
      if (!val) {
        bs.circle.remove();
        continue;
      }
      bs.circle.addTo(mymap);
    }
  }
  static Start() {

    // --------- Создание карты. ---------
    mymap = L.map('mapid').setView([56.811276, 60.631417], 13);
    // Добавляем на нашу карту слой OpenStreetMap
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mymap);


    // --------- Логика show_map_panel_button ---------
    let showMapPanelButton = document.getElementById('show_map_panel_button') as HTMLInputElement;
    let mapPanel = document.getElementById('map_panel') as HTMLDivElement;
    showMapPanelButton.addEventListener('click', (event) => {
      if (mapPanel.style.display !== 'none') {
        mapPanel.style.display = 'none';
      } else {
        mapPanel.style.display = 'initial';
      }
    });

    // --------- Realtime трекер, фильтр ---------

    // Чекбокс трекера.
    let showTracksCheckbox = document.getElementById('show_tracks_checkbox') as HTMLInputElement;
    showTracksCheckbox.addEventListener('click', (event) => {
      if (showTracksCheckbox.checked) {
        MapManager.Tracker.turnOn = true;
        for (let c of Array.from(Array.from(MapManager.endpointDevices.values()))) {
          c.updateTrackerStatus();
        }
      } else {
        MapManager.Tracker.turnOn = false;
        for (let c of Array.from(MapManager.endpointDevices.values())) {
          c.updateTrackerStatus();
        }
      }
    });

    // Текстбокс трекера.
    let amountOfTrackPointTextInput = document.getElementById('amount_of_track_point_textinput') as HTMLInputElement;
    amountOfTrackPointTextInput.value = MapManager.Tracker.amountOfPoints.toString();
    amountOfTrackPointTextInput.addEventListener('change', (event) => {
      if (Number(amountOfTrackPointTextInput.value) < 0) {
        return;
      }
      if (Number(amountOfTrackPointTextInput.value) === NaN) {
        return;
      }
      if (Number(amountOfTrackPointTextInput.value) <= MapManager.Tracker.MAX_AMOUNT_OF_POINTS) {
        MapManager.Tracker.amountOfPoints = Number(amountOfTrackPointTextInput.value);
      } else {
        MapManager.Tracker.amountOfPoints = Number(MapManager.Tracker.MAX_AMOUNT_OF_POINTS);
      }
      for (let c of Array.from(MapManager.endpointDevices.values())) {
        c.updateTrackerStatus();
      }
    });

    // Чекбокс фильтра.
    let realtimeFilterCheckbox = document.getElementById('realtime_filter_checkbox') as HTMLInputElement;
    realtimeFilterCheckbox.addEventListener('click', (event) => {
      if (realtimeFilterCheckbox.checked) {
        MapManager.Filter.turnOn = true;
        for (let c of Array.from(MapManager.endpointDevices.values())) {
          c.updateTrackerStatus();
        }
      } else {
        MapManager.Filter.turnOn = false;
        for (let c of Array.from(MapManager.endpointDevices.values())) {
          c.updateTrackerStatus();
        }
      }
    });

    // Текстбокс фильтра.
    let realtimeFilteredDevicesTextinput = document.getElementById('realtime_filtered_devices_textinput') as HTMLInputElement;
    realtimeFilteredDevicesTextinput.addEventListener('change', (event) => {
      MapManager.Filter.filterLabel = realtimeFilteredDevicesTextinput.value;
      MapManager.Filter.setNewEpdsForFiltering(realtimeFilteredDevicesTextinput.value);
      for (let c of Array.from(MapManager.endpointDevices.values())) {
        c.updateTrackerStatus();
      }
    });

    let showHistoryButton = document.getElementById('show_history_button') as HTMLInputElement;
    let historyStartDateInput = document.getElementById('history_start_date') as HTMLInputElement;
    let historyStartTimeInput = document.getElementById('history_start_time') as HTMLInputElement;
    let historyEndDateInput = document.getElementById('history_end_date') as HTMLInputElement;
    let historyEndTimeInput = document.getElementById('history_end_time') as HTMLInputElement;
    let realTimeH = document.getElementById('realTimeHeader') as HTMLHeadElement;
    let historyH = document.getElementById('historyHeader') as HTMLHeadElement;

    let setMarkerTime = (isReal: boolean) => {
      if (isReal) {
        realTimeH.innerHTML = `✓ ${realTime}`;
        historyH.innerHTML = hist
      }
      else {
        realTimeH.innerHTML = realTime;
        historyH.innerHTML = `✓ ${hist}`;
      }
      History.turnOn = !isReal;
    }

    // Show history Button
    let showButtonHandler = () => {
      if (!MapManager.EpdsHistory.turnOn) {
        let startTimeStamp = [];
        for (let c of historyStartDateInput.value.split('-')) startTimeStamp.push(Number(c));
        let startTime = historyStartTimeInput.value;
        startTime = startTime == '' ? "00:00" : startTime;
        for (let c of startTime.split(':')) startTimeStamp.push(Number(c));
        let endTimeStamp = [];
        for (let c of historyEndDateInput.value.split('-')) endTimeStamp.push(Number(c));
        let endTime = historyEndTimeInput.value;
        endTime = endTime == '' ? "23:59" : endTime;
        for (let c of endTime.split(':')) endTimeStamp.push(Number(c));

        if (startTimeStamp[0] < 1980 || endTimeStamp[0] < 1980) {
          throw 'A wrong date was set.';
        }
        MapManager.EpdsHistory.startDateLabel = historyStartDateInput.value;
        MapManager.EpdsHistory.startTimeLabel = historyStartTimeInput.value;
        MapManager.EpdsHistory.endDateLabel = historyEndDateInput.value;
        MapManager.EpdsHistory.endTimeLabel = historyEndTimeInput.value;
        while (startTimeStamp.length < 6) startTimeStamp.push(0);
        while (endTimeStamp.length < 6) endTimeStamp.push(0);

        connection.invoke('SendDataForHistory', startTimeStamp, endTimeStamp).catch(function (err) {
          return console.error(err.toString());
        });
        MapManager.EpdsHistory.turnOn = true;
        for (let c of Array.from(MapManager.endpointDevices.values())) c.updateTrackerStatus();
        for (let c of Array.from(baseStations.values())) c.hide();
        setMarkerTime(false);
        showHistoryButton.innerHTML = 'Скрыть историю';
      } else {
        MapManager.EpdsHistory.turnOn = false;
        for (let c of Array.from(MapManager.EpdsHistory.endpointDevices.values())) c.remove();
        MapManager.EpdsHistory.endpointDevices.clear();
        for (let c of Array.from(MapManager.endpointDevices.values())) c.updateTrackerStatus();
        for (let c of Array.from(baseStations.values())) c.show();
        setMarkerTime(true);
        showHistoryButton.innerHTML = 'Показать историю';
      }
    }
    showHistoryButton.addEventListener('click', showButtonHandler);

    // Текстбокс фильтра history.
    let historyFilteredDevicesTextinput = document.getElementById('history_filtered_devices_textinput') as HTMLInputElement;
    MapManager.EpdsHistory.setNewEpdsForFiltering(historyFilteredDevicesTextinput.value);

    historyFilteredDevicesTextinput.addEventListener('change', (event) => {
      MapManager.EpdsHistory.setNewEpdsForFiltering(historyFilteredDevicesTextinput.value);
      for (let c of Array.from(MapManager.EpdsHistory.endpointDevices.values())) {
        c.updateTrackerStatus();
      }
    });

    // --------- Base stations  ---------
    class BaseStation {
      modelObj;
      marker;
      circle;
      constructor(modelObj: any) {
        this.modelObj = modelObj;
        this.marker = L.marker([modelObj.latitude, modelObj.longtitude], {
          icon: icon
        }).bindPopup(this.createPopupMessage(modelObj));
        this.circle = L.circle([modelObj.latitude, modelObj.longtitude], {
          color: 'silver',
          fillColor: '#505000',
          fillOpacity: 0.15,
          radius: 3500
        });
      }

      createPopupMessage(modelObj: any) {
        let popupMessage = '<pre>';
        popupMessage += `<b>BS:       ${modelObj.id}</b><br/>`;
        popupMessage += `Широта:      ${modelObj.latitude}<br/>`;
        popupMessage += `Долгота:     ${modelObj.longtitude}<br/>`;
        popupMessage += `t в корпусе: ${modelObj.temperatureInside}<br/>`;
        popupMessage += `t cнаружи:   ${modelObj.temperatureOutside}<br/>`;
        popupMessage += `Давление:    ${modelObj.pressure}</pre>`;
        return popupMessage;
      }

      show() {
        this.marker.addTo(mymap);
        if (MapManager.circleEnabled) {
          this.circle.addTo(mymap);
        }
      }

      hide() {
        this.marker.remove();
        this.circle.remove();
      }
    }

    baseStations = new Map();



    // --------- SignalR  ---------

    // Контейнеры для хранения сведений о уже полученных с сервера данных об EPD. Используются при запросе новых данных (что бы сервер отправлял не все данные, а только те что отсутствуют на фронте).
    class AcceptedMapPoint {
      id;
      lastPointNumber;
      constructor(id: any, lastPointNumber: any) {
        this.id = id;
        acceptedEpdIds.add(id);
        this.lastPointNumber = lastPointNumber;
      }

      updateLastPointNumber(newLastPointNumber: any) {
        this.lastPointNumber = newLastPointNumber;
      }
    }
    let acceptedMapPoints: any[] = []; // Содержит AcceptedMapPoint
    let acceptedEpdIds = new Set();


    let connection = new signalR.HubConnectionBuilder().withUrl('/mapDataHub').build();
    connection.on('TransmitDataForRealtime', function (bsData, epdData) {
      // Base stations
      for (let obj of bsData) {
        if (baseStations.has(obj.id)) {
          let bs = baseStations.get(obj.id);
          let newLatLgn = new L.LatLng(obj.latitude, obj.longtitude);
          bs.marker.setLatLng(newLatLgn);
          bs.circle.setLatLng(newLatLgn);
          bs.marker._popup.setContent(bs.createPopupMessage(obj));
        } else {
          let newBs = new BaseStation(obj);
          baseStations.set(obj.id, newBs);
          newBs.show();
        }
      }
      // EndpointDevices
      for (let obj of epdData) {
        if (acceptedEpdIds.has(obj.id)) {
          for (let c of acceptedMapPoints) {
            if (c.id === obj.id) c.updateLastPointNumber(obj.numberOfLastMapPoint);
          }
        } else {
          acceptedMapPoints.push(new AcceptedMapPoint(obj.id, obj.numberOfLastMapPoint));
        }
        if (MapManager.endpointDevices.has(obj.id)) {
          let epd = MapManager.endpointDevices.get(obj.id);
          for (let c of obj.mapPoints) {
            if (epd == undefined) {
              break;
            }
            epd.addNewPoint(obj, c);
          }
        } else {
          let newEpd = new EndpointDevice(obj);
          MapManager.endpointDevices.set(obj.id, newEpd);
        }
      }
    });

    connection.on('TransmitDataForHistory', function (epdData) {
      if (!MapManager.EpdsHistory.turnOn) {
        return;
      }
      MapManager.EpdsHistory.endpointDevices.clear();
      for (let obj of epdData) {
        let newEpd = new EndpointDevice(obj, false);
        MapManager.EpdsHistory.endpointDevices.set(obj.id, newEpd);
      }
      for (let c of Array.from(MapManager.EpdsHistory.endpointDevices.values())) {
        c.updateTrackerStatus();
      }

    });

    connection.start().then(function () {
      if (MapManager.EpdsHistory.turnOn) {
        for (let bs of Array.from(baseStations.values())) {
          bs.hide();
        }
        MapManager.EpdsHistory.turnOn = false;
        showButtonHandler();
      }
    }).catch(function (err) {
      return console.error(err.toString());
    });

    setInterval(() => {
      if (MapManager.EpdsHistory.turnOn) { return; }
      connection.invoke('UpdateDevicesData', acceptedMapPoints).catch(function (err) {
        return console.error(err.toString());
      });
    }, 1000);
  }
}

export default MapManager;