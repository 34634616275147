import * as React from 'react';
import { BrowserRouter as Router, Route, Redirect, Link, Switch } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import manager, { CommandMode, ServiceData, BsCommand } from '../MainPanelScripts/SystemManagment/system_managment_manager';
import style from '../../css/system_managment.module.css';

let bsCommandsHubConnection = new signalR.HubConnectionBuilder().withUrl('/bsCommandsHub').build();
let serviceDataHubConnection = new signalR.HubConnectionBuilder().withUrl('/serviceDataHub').build();

bsCommandsHubConnection.start().then(() => {
    manager.setSignalRBsCommand(bsCommandsHubConnection);
});
serviceDataHubConnection.start().then(() => {
    manager.setSignalRServiceData(serviceDataHubConnection);
});

//serviceDataHubConnection.invoke('GetServiceFiles')
//.then((rec: any[]) => { serviceFiles = rec; })
//.catch((error: any) => { alert(String(error)); serviceFiles = []; });


export class SystemManagment extends React.Component<{}, { serviceFiles: any[] }> {
    componentDidMount() {
        manager.Start();
    }
    render() {
        return (
            <Router>
                <div className={style.system_managment}>
                    <Redirect to="/SystemManagment/CommandMode" />
                    <table id="management-menu">
                        <tbody>
                            <tr><td><Link to="/SystemManagment/CommandMode" >Командный режим</Link></td></tr>
                            <tr><td><Link to="/SystemManagment/ServiceData" >Управление сервисными данными</Link></td></tr>
                            <tr><td><Link to="/SystemManagment/CommandBs" >Управление БС</Link></td></tr>
                            <tr><td><Link to="/SystemManagment/AdminBs" >Администрирование БС</Link></td></tr>
                            <tr><td><Link to="/SystemManagment/AdminEpd">Администрирование устройств</Link></td></tr>
                        </tbody>
                    </table>
                    <br />
                    <div id="manag-content-command-mode">
                        <Switch>
                            <Route path="/SystemManagment/CommandMode">
                                <CommandMode />
                            </Route>
                            <Route path='/SystemManagment/ServiceData'>
                                <ServiceData />
                            </Route>
                            <Route path="/SystemManagment/CommandBs">
                                <BsCommand />
                            </Route>
                            <Route path="/SystemManagment/AdminBs">
                                <h1>В разработке</h1>
                            </Route>
                            <Route path='/SystemManagment/AdminEpd'>
                                <h1>В разработке</h1>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Router >
        );
    }

}
