import * as React from 'react';
import { render } from 'react-dom';
import { Index } from './Views/index';
import { Panel } from './panel';

export default () => {
    render(<Index/>, document.getElementById('root'));
    document.addEventListener('succesfullAuthorization', () => {
        render(<Panel />, document.getElementById('root'))
    });
}