import * as signalR from '@microsoft/signalr';
import * as React from 'react';
import { CommandMode } from './command_mode';
import {
  ServiceData,
  DOWNLOAD_SERVICE_FILE_EVENT_NAME,
  DownloadServiceFileProps,
  REMOVE_SERVICE_FILE_EVENT_NAME,
  RemoveServiceFileEventProps
} from './service_data';
import { BsCommand } from './bs_command';

function executeBsCommand(ob: any) {
  let details = (ob as CustomEvent<string[]>).detail;
  // Элементы массива event.detail находятся в том порядке, в котором находятся элементы для ввода на странице
  if (details[0] == '5') {
    details[1] = details[1][details[1].length - 1];
  }
  if (!details.includes('')) {
    let args: number[] = [Number(details[0])];
    switch (args[0]) {
      case 1:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[3]));
        args.push(Number(details[2]));
        break;
      case 2:
        args.push(Number(details[1]));
        args.push(Number(details[2]));
        args.push(Number(details[4]));
        args.push(Number(details[3]));
        break;
      case 3:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[2]));
        args.push(0);
        break;
      case 4:
        args.push(Number(details[1]));
        args.push(Number(details[2]));
        args.push(Number(details[3]));
        args.push(0);
        break;
      case 5:
        args.push(Number(details[1]));
        args.push(Number(details[2]));
        args.push(Number(details[3]));
        args.push(0);
        break;
      case 6:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[3]));
        args.push(Number(details[2]));
        break;
      case 7:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[2]));
        args.push(0);
        break;
      case 8:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[3]));
        args.push(Number(details[2]));
        break;
      case 9:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[3]));
        args.push(Number(details[2]));
        break;
      case 10:
        args.push(Number(details[1]));
        args.push(Number(details[2]) * Number(details[3]) * 1024);
        args.push(Number(details[5]));
        args.push(Number(details[4]));
        break;
      case 11:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[2]));
        args.push(0);
        break;
      case 12:
        args.push(Number(details[1]));
        args.push(0);
        args.push(Number(details[2]));
        args.push(0);
        break;
    }
    SystemMessageManager.signalRBsCommand.invoke('ExecuteSlpCommand', args[0], args[1], args[2], args[3], args[4]).catch(function (err) {
      console.error(err.toString());
    });
  } else
    alert('Введите больше аргументов.');
}

let serviceFiles: any[] = [];

function updateServiceFiles() {
  SystemMessageManager.signalRServiceData.invoke('GetServiceFiles').then((received: any[]) => {
    serviceFiles = received;
    window.dispatchEvent(new CustomEvent<UpdateServiceFilesEventProps>(updateServiceFilesEventName,
      { detail: { serviceFiles: received } }
    ));
  });
}
class SystemMessageManager {
  static setSignalRBsCommand(ob: signalR.HubConnection) {
    SystemMessageManager.signalRBsCommand = ob;
    ob.on('alert', (text: string) => { alert(text); });
  }
  static setSignalRServiceData(ob: signalR.HubConnection) {
    SystemMessageManager.signalRServiceData = ob;
    ob.on('alert', (text: string) => { alert(text); });
    updateServiceFiles();
    window.addEventListener(REMOVE_SERVICE_FILE_EVENT_NAME, function (event: any) {
      let ev = event as CustomEvent<RemoveServiceFileEventProps>;
      SystemMessageManager.signalRServiceData.invoke('RemoveServiceFile', ev.detail.id).then(updateServiceFiles);
    });
    window.addEventListener(DOWNLOAD_SERVICE_FILE_EVENT_NAME, function (result: any) {
      let res = result as CustomEvent<DownloadServiceFileProps>;
      let args = res.detail;
      SystemMessageManager.signalRServiceData.invoke('UploadFileWithServiceData',
        args.servicePackName, args.serviceFileName, args.servicePackType, args.servicePackVer, args.data);
      updateServiceFiles();
    });
  }
  static signalRBsCommand: signalR.HubConnection;
  static signalRServiceData: signalR.HubConnection;

  static Start() {
    window.addEventListener(CommandMode.buttonExecutedEventType, executeBsCommand);
  }
}


class HigherServiceData extends React.Component<{}, { serviceFiles: any[] }> {
  constructor(props: any) {
    super(props);
    this.state = { serviceFiles: serviceFiles };
    this.updateServiceFiles = this.updateServiceFiles.bind(this);
    window.addEventListener(updateServiceFilesEventName, this.updateServiceFiles);
  }
  updateServiceFiles(event: any) {
    let serviceFiles = (event as CustomEvent<UpdateServiceFilesEventProps>).detail.serviceFiles;
    try {
      this.setState({ serviceFiles: serviceFiles });
    }
    catch { }
  }
  render() {
    return <ServiceData serviceFiles={this.state.serviceFiles} />
  }
}
class HigherCommandMode extends React.Component<{}, { serviceFiles: any[] }>{
  constructor(props: any) {
    super(props);
    this.state = { serviceFiles: serviceFiles };
    this.updateServiceFiles = this.updateServiceFiles.bind(this);
    window.addEventListener(updateServiceFilesEventName, this.updateServiceFiles);
  }
  updateServiceFiles(event: any) {
    let serviceFiles = (event as CustomEvent<UpdateServiceFilesEventProps>).detail.serviceFiles;
    this.state = { serviceFiles: serviceFiles };
  }
  render() {
    return <CommandMode serviceFiles={this.state.serviceFiles} />
  }
}
const updateServiceFilesEventName = 'receivedNewServiceFiles';
interface UpdateServiceFilesEventProps { serviceFiles: any[]; }
export { HigherCommandMode as CommandMode, HigherServiceData as ServiceData, BsCommand };
export default SystemMessageManager;