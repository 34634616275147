import * as React from 'react';

interface SelectProps {
  label: string,
  items: string[],
  uniqId: number
}

interface InputProps {
  label: string,
  defValue?: string,
  uniqId: number
}

class Sselect extends React.Component<SelectProps>  {
  render() {
    let options: JSX.Element[] = [];
    for (const val of Array.from(this.props.items)) {
      options.push(<option value={val} key={value++}>{val}</option>);
    }
    return (
      <tr>
        <td>{this.props.label}</td>
        <td><select id={this.props.uniqId.toString()}>{options}</select></td>
      </tr>
    );
  }
}

class Sinput extends React.Component<InputProps> {
  render() {
    return (
      <tr>
        <td>{this.props.label}</td>
        <td><input id={this.props.uniqId.toString()} defaultValue={this.props.defValue}></input></td>
      </tr>);
  }
}

let value = 0;

let commandType = 1;
function formatDate(dateTime: Date) {
  let all = dateTime.toString().split(/-|:|[T]|[.]/);
  return `${all[2]}.${all[1]}/${all[3]}:${all[4]}`;
}
class Body extends React.Component<{ serviceFiles: any[] }, { items: JSX.Element[] }> {
  static buttonExecutedEventType = 'buttonExecuted';
  commandModeTabs = [
    [< h1 > В разработке</h1 >],
    [
      <Sselect label='EPO-файл для трансляции' key={value++} items={this.getNamesList(1)} uniqId={value} />,
      <Sinput label='ID мастер BS' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sselect label='Файл прошивки' items={this.getNamesList(2)} key={value++} uniqId={value} />,
      <Sinput label='Номер SU' key={value++} uniqId={value} />,
      <Sinput label='ID мастер BS' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sinput label='Номер SU' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sinput label='Тип тревоги' key={value++} uniqId={value} />,
      <Sinput label='Количество повторений трансляций' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sselect label='Тип тревоги' items={['Тип 1', 'Тип 2']} key={value++} uniqId={value} />,
      <Sinput label='Номер SU' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sselect label='Файл прошивки' items={this.getNamesList(3)} key={value++} uniqId={value} />,
      <Sinput label='ID мастер BS' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sinput label='Номер SU' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sselect label='Файл прошивки' items={this.getNamesList(1)} key={value++} uniqId={value} />,
      <Sinput label='ID мастер BS' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sselect label='Файл прошивки' items={this.getNamesList(1)} key={value++} uniqId={value} />,
      <Sinput label='ID мастер BS' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sselect label='Файл прошивки' items={this.getNamesList(0, 1, 4)} key={value++} uniqId={value} />,
      <Sinput label='Номер кадра' key={value++} uniqId={value} />,
      <Sinput label='Номер канала' key={value++} uniqId={value} />,
      <Sinput label='ID мастер BS' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sinput label='Номер SU' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ],
    [
      <Sinput label='Номер TS' key={value++} uniqId={value} />,
      <Sinput label='Смещение временной точки (ms)' defValue='4000' key={value++} uniqId={value} />
    ]
  ];
  getNamesList(...types: number[]) {
    let result: string[] = [];
    for (const file of this.props.serviceFiles) {
      if (types.includes(file.type)) {
        result.push(`${file.name}(${formatDate(file.addTime)})`);
      }
    }
    return result;
  }
  configureDivFilling(value: number) {
    commandType = value;
    if (value < this.commandModeTabs.length) {
      return this.commandModeTabs[value];
    }
    return this.commandModeTabs[0];
  }
  constructor(props: any) {
    super(props);
    this.configureDivFilling = this.configureDivFilling.bind(this);
    this.getNamesList = this.getNamesList.bind(this);
    this.state = { items: this.configureDivFilling(1) };
    this.dataTableOnchange = this.dataTableOnchange.bind(this);
  }
  getBsExecuteArguments() {
    let args: string[] = [commandType.toString()];
    if (commandType > 0)
      for (let item of this.state.items) {
        let key: string = String(item.key);
        // Похоже на костыль, но это и есть костыль
        let target = document.getElementById(key);
        if (target?.nodeName == 'SELECT') {
          let option = (target as HTMLSelectElement).selectedOptions[0]
          if (option == undefined)
            args.push('');
          else {
            let value = String(option.value);
            if (isNaN(Number(value))) {
              for (let file of this.props.serviceFiles) {
                if (`${file.name}(${formatDate(file.addTime)})` == value) {
                  value = file.version;
                }
              }
            }
            args.push(value);
          }
        }
        else if (target?.nodeName == 'INPUT') {
          let value = (target as HTMLInputElement).value;
          args.push(value);
        }
        else {
          args.push('');
        }
      }
    return args;
  }
  dataTableOnchange(event: React.ChangeEvent) {
    let value = (event.target as HTMLSelectElement).selectedIndex;
    value++;
    this.setState({ items: this.configureDivFilling(value) });
  }
  render() {
    return (
      <>
        <br />
        <h3>Командный режим.</h3>
        <br />
        <br />
        <select onChange={this.dataTableOnchange} id="management-command-select" placeholder="sdfsdf">
          <option value="command_1">Транслировать EPO</option>
          <option value="command_2">Прошить одиночный SU</option>
          <option value="command_3">Запросить версию прошивки SU</option>
          <option value="command_4">Подать всеобщую тревогу</option>
          <option value="command_5">Подать тревогу на единичный SU</option>
          <option value="command_6">Перепрошить все TS</option>
          <option value="command_7">Снять устройство с регистрации </option>
          <option value="command_8">Перепрошить все SU со сверкой версии</option>
          <option value="command_9">Перепрошить все SU без сверки версии</option>
          <option value="command_10">Перепрошить устройства с выбором канала/кадра</option>
          <option value="command_11">Перерегистрировать устройство</option>
          <option value="command_12">Смена настроек LBS</option>
        </select>
        <button id="execute_slp_command_button" onClick={() => { dispatchEvent(new CustomEvent(Body.buttonExecutedEventType, { detail: this.getBsExecuteArguments() })); }}>Исполнить команду</button>
        <br /><br />
        <span id="slp-command-status-label">Заполните все параметры команды и нажмите кнопку "Исполнить команду".</span>
        <br />
        <br />
        <div id='commands-sending-board'>
          <table id="commands-sending-board-table">
            <tbody>{this.state.items}</tbody>
          </table>
        </div>
      </>);
  }
}

export { Body as CommandMode };