import * as React from 'react';
import { BrowserRouter as Router, Route, Redirect, Link, Switch } from 'react-router-dom';
import { SlpData } from './Views/slp_data';
import { Map } from './Views/map';
import { SystemManagment } from './Views/system_managment';
import toStart from './application';
import style from '../css/panel.module.css';

export function Panel() {
    return (
        <div className={style.root}>
            <Router>
                <Redirect from='/login' to='/Map' />
                <header id='header' className={style.header}>
                    <nav>
                        <ul>
                            <li >
                                <div>
                                    <button>
                                        <Link to='/Statistics'><b>Статистика</b></Link>
                                    </button>
                                </div>
                            </li >
                            <li >
                                <div>
                                    <button>
                                        <Link to="/SlpData"><b>Данные</b></Link>
                                    </button>
                                </div>
                            </li >
                            <li >
                                <div>
                                    <button>
                                        <Link to="/BsBoard"><b>Базовые станции</b></Link>
                                    </button>
                                </div>
                            </li >
                            <li >
                                <div>
                                    <button>
                                        <Link to="/EpdBoard"><b>Устройства</b></Link>
                                    </button>
                                </div>
                            </li >
                            <li >
                                <div>
                                    <button>
                                        <Link to="/Map"><b>Карта</b></Link>
                                    </button>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <button>
                                        <Link to="/SystemManagement"><b>Управление</b></Link>
                                    </button>
                                </div>
                            </li>
                            <li>
                                <div >
                                    <button>
                                        <Link to="/Settings"><b>Настройки</b></Link>
                                    </button>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <button onClick={toStart}>
                                        <Link to="/login"><b>Выйти</b></Link>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </header>
                <main>
                    <Switch>
                        <Route path="/Statistics">
                            <br />
                            <h1>В разработке</h1>
                        </Route>
                        <Route path='/SlpData'>
                            <SlpData />
                        </Route>
                        <Route path="/BsBoard">
                            <br />
                            <h1>В разработке</h1>
                        </Route>
                        <Route path="/EpdBoard">
                            <br />
                            <h1>В разработке</h1>
                        </Route>
                        <Route path='/Map'>
                            <Map />
                        </Route>
                        <Route path='/SystemManagement'>
                            <SystemManagment />
                        </Route>
                        <Route path='/Settings'>
                            <br />
                            <h1>В разработке</h1>
                        </Route>
                    </Switch>
                </main>
            </Router>
        </div>);
}